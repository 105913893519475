import { queryParams } from "../utils";
import {
  CityParameters,
  City,
  StreetParameters,
  Street,
  AddressValidationParameters,
  AddressValidation,
  TariffParameters,
  Tariff,
} from "../types";
import {
  citiesApi,
  streetsApi,
  addressValidationApi,
  tariffApi,
} from "../settings";

import request from "./request";

export const getCities = (params: CityParameters): Promise<City[]> => {
  return request(queryParams(citiesApi, params).toString());
};

export const getStreets = (params: StreetParameters): Promise<Street[]> => {
  return request(queryParams(streetsApi, params).toString());
};

export const getAddressValidation = (
  params: AddressValidationParameters
): Promise<AddressValidation> => {
  return request(queryParams(addressValidationApi, params).toString());
};

export const getTariff = (params: TariffParameters): Promise<Tariff> => {
  return request(queryParams(tariffApi, params).toString());
};
