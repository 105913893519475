import { UrlParams } from "../types";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];

/**
 * Add query parameters to URL address
 *
 * @param url URL address
 * @param params Query parameters
 *
 * @return URL with query parameters
 */
export const queryParams = (
  url: string,
  params: Record<string, unknown>
): URL => {
  const result = new URL(url);
  Object.keys(params).forEach((key) =>
    result.searchParams.append(key, getKeyValue(key)(params))
  );

  return result;
};

export const parseQueryParams = (params: string): UrlParams => {
  const result: UrlParams = {};
  if (params[0] === "?") {
    params = params.slice(1);
  }

  params.split("&").forEach((part: string) => {
    const [key, value] = part.split("=");
    const decodedValue = decodeURIComponent(value).replace("+", " ");
    result[key] = decodedValue;
  });
  return result;
};
