import { RequestState } from "../types";

/**
 * Create RequestState init object
 *
 * @param initData initial data
 *
 * @return RequestState object
 */
export function createRequestState<T>(
  initData: T | null = null
): RequestState<T> {
  return { isPending: false, data: initData, error: null };
}
