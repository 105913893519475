export const domain = process.env.GATSBY_API_URL;
export const v1 = `${domain}/v1`;

// Endpoints
export const confirmRestorePasswordApi = `${v1}/customers/confirm-restore-password`;
export const citiesApi = `${v1}/cities`;
export const streetsApi = `${v1}/addresses/streets-by-postcode`;
export const addressValidationApi = `${v1}/addresses/valid`;
export const tariffApi = `${v1}/tariffs`;
export const privacyPolicyApi = `${v1}/customers/policy`;
export const providersApi = `${v1}/providers`;
export const createContractApi = `${v1}/contracts`;
export const ibanApi = `${v1}/payments/ibans/`;
export const promoCodeApi = `${v1}/contracts/promo-codes/`;
export const emailApi = `${v1}/customers/emails/`;
export const verifyEmailApi = `${v1}/customers/activation/`;
export const changeEmailApi = `${v1}/customers/confirm-change-email/`;
export const freshDeskApi = `https://stromee.freshdesk.com/api/v2/`;

export const development = domain?.includes("dev");
