import {
  Provider,
  ProviderParameters,
  Contract,
  Iban,
  PromoCode,
} from "../types";
import request, { requestWithEmptyResponse } from "./request";

import { queryParams } from "../utils";
import {
  providersApi,
  createContractApi,
  ibanApi,
  emailApi,
  promoCodeApi,
} from "../settings";

export const getProviders = (
  params: ProviderParameters
): Promise<Provider[]> => {
  return request(queryParams(providersApi, params).toString());
};

export const createContract = (contract: Contract): Promise<any> => {
  return request(createContractApi, {
    method: "post",
    body: JSON.stringify(contract),
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  });
};

export const getIban = (param: string): Promise<Iban> => {
  return request(`${ibanApi}${param}`);
};

export const getPromoCode = (param: string): Promise<PromoCode> => {
  return request(`${promoCodeApi}${param}`);
};

export const checkEmail = (param: string): Promise<any> => {
  return requestWithEmptyResponse(encodeURI(`${emailApi}${param}`), {
    method: "HEAD",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  });
};
